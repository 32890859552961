import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import '../../../styles/deck.scss';
import DeckTimeFilter from '@/components/Elements/DeckTimeFilter';
import ExploreDeckCard from '@/components/Elements/ExploreDeckCard';
import DeckFooter from '@/components/Layout/DeckFooter';
import { useDecks } from '@/features/decks/api/getDecks';
import { filterDataBasedOnTime, getUtmMedium, manageFilters } from '@/utils/utility';
import { decksCardsData} from '@/utils/constant';

const Subscribe = lazy(() => import('@/features/misc/pages/common/Subscribe'));
const Expert = lazy(() => import('@/features/misc/pages/common/Expert'));
const Convinced = lazy(() => import('@/features/misc/pages/common/Convinced'));
const FAQDeck = lazy(() => import('@/features/misc/pages/common/FAQDeck'));
const MainHeader = lazy(() => import('@/features/misc/pages/common/MainHeader'));
const Featured = lazy(() => import('@/features/misc/pages/common/Featured'));
const FeatureSection = lazy(() => import('@/features/misc/pages/common/FeatureSection'));
const HowItWorks = lazy(() => import('@/features/misc/pages/common/HowItWorks'));

const DeckNew = () => {
    const decksQuery = useDecks({ config: {} });
    const deckRef : any = useRef(null);
    const sliderRef : any = useRef(null);
    const device = sliderRef.current?.state.deviceType === 'mobile' ? 1 : 3;

    const [selectedTime, setSelectedTime] = useState('1 Month');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [decksData, setDecksData] = useState([]);
    const [filters, setFilters] = useState<any>([]);
    const [lowestPackage, setLowestPackage] = useState('');

    const utmData = getUtmMedium();

    useEffect(() => {
        if (!decksQuery?.isLoading && decksQuery?.data?.length) {
            const response = filterDataBasedOnTime(decksQuery?.data, selectedTime);
            setDecksData(response.filterdData);
            setLowestPackage(response.lowestPackage)
            console.log('11111')
        }
    }, [decksQuery.data, selectedTime]);

    useEffect(() => {
      if (decksQuery.data) {
        const response = manageFilters(decksQuery.data);
        setSelectedTime(response.selectedTime);
        setFilters(response.filters);
      }
    }, [decksQuery.data]);

    const handleNextSlide = () => {
        if (currentSlide >= decksCardsData?.length - device) { return }
        const nextSlide = currentSlide + 1;
        setCurrentSlide(nextSlide);
        if (sliderRef.current) {
            sliderRef.current.goToSlide(nextSlide);
        }
    };

    const handlePreviousSlide = () => {
        if (currentSlide === 0) { return }
        const nextSlide = currentSlide - 1;
        setCurrentSlide(nextSlide);
        if (sliderRef.current) {
            sliderRef.current.goToSlide(nextSlide);
        }
    };

    return (
        <div className='deck-body'>
            <Suspense fallback={<div>.</div>}>
            <MainHeader deckRef={deckRef} />
            <Featured />
            <Convinced
                sliderRef={sliderRef}
                handlePreviousSlide={handlePreviousSlide}
                handleNextSlide={handleNextSlide}
              />

              <section className='explore-decks' ref={deckRef}>
                <h2 className='text-center text-black mb-3 mb-lg-4 deck-common-sub-heading'>Choose a Plan</h2>
                <p className='text-black deck-common-body-md text-center text-capitalize'>Subscribe to a Plan and start building your portfolio</p>

                {filters.length > 0 && (
                    <DeckTimeFilter
                        data={filters}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                    />
                )}

                <div className='container d-flex flex-column align-items-center justify-content-center'>
                    {decksQuery.isLoading ? (
                        <p className='fw-medium mt-3'>Loading</p>
                    ) : (
                        decksData.map((deck, index) => (
                            <ExploreDeckCard
                                key={index}
                                deck={deck}
                                selectedTime={selectedTime}
                                utmData={utmData}
                            />
                        ))
                    )}
                </div>
            </section>

              <FeatureSection />


              <Expert />

              <section className='works container'>
                <h2 className='text-center deck-common-sub-heading'>How it works</h2>
                <HowItWorks />
              </section>

            <Subscribe />

            <FAQDeck />
            <DeckFooter />

            </Suspense>
            {decksQuery?.data?.length > 0 && (
                <div className='d-flex align-content-center justify-content-between d-md-none position-fixed bottom-0 w-100 offer-section overflow-hidden'>
                    <div>
                        <h3 className='title-offer'>Starts at &#8377;{lowestPackage}</h3>
                        <p className='desc-offer'>
                            <span className='text-decoration-line-through' style={{ fontSize: '0.875rem' }}>
                                &#8377;999
                            </span> Special Launch Offer
                        </p>
                    </div>

                    <button onClick={() => deckRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                        Subscribe Now
                    </button>
                </div>
            )}
        </div>
    );
};

export default DeckNew;
