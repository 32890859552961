import React from 'react'
import ColorFlare from '../../assets/images/common/purple-flare.webp'
import DOMPurify from 'dompurify'
import { useNavigate, useParams } from 'react-router-dom'

const ExploreDeckCard = ({ deck, selectedTime, utmData }: any) => {

    const { coupon } = useParams();
    const navigate = useNavigate();
    const filterData = deck?.channel_packages?.filter((item: any) => selectedTime === item.plan_type)[0];

    const handleSubscribe = () => {
        if (coupon) {
            return navigate(`/decks/purchase/${deck.id}/${filterData.id}?${utmData}coupon=${coupon}`)
        }
        navigate(`/decks/purchase/${deck.id}/${filterData.id}?${utmData}`)
    }

    const getPricingInfo = () => {
        if (filterData?.plan_type) {
            return `For ${filterData?.plan_type}`
        } else {
            return `For ${filterData?.num_of_cards} Stock Picks`
        }
    }

    return (
        <div className='position-relative my-4 mx-3 mx-md-0'>
            <img src={ColorFlare} className='color-flare' draggable={false} />

            <div className='explore-deck-card row'>
                <div className='col-12 col-md-5 d-flex flex-column justify-content-between'>

                    <div>
                        <h2 className='deck-name'>{deck.title}</h2>
                        <div className='d-flex align-items-center mt-0 mt-md-2'>
                            <p className='deck-category'>{deck.short_description}</p>
                            <div className='deck-returns ms-3'>
                                {filterData?.plan_type || deck?.time_horizon}
                            </div>
                        </div>
                    </div>

                    <div>
                        <p className='deck-price'>&#8377;{filterData?.price}<span className='deck-price-details'>/ {getPricingInfo()}</span></p>
                        <button className='subscribe-btn' onClick={handleSubscribe}>Subscribe Now</button>
                    </div>
                </div>

                <div className='col-12 col-md-7'>
                    <div className='d-flex' style={{ height: '100%' }}>
                        <div className='spacer' />
                        <div>
                            <hr className='my-5 d-block d-md-none' />
                            <div className="deck-info" dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(deck.description)
                            }} >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExploreDeckCard
