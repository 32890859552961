import * as React from 'react';
import { PurchaseStepProps } from '../types';
import { Badge } from './Badge';

export function PurchaseStep({ completed, active, children, title, id }: React.PropsWithChildren<PurchaseStepProps>) {

  return (
    <div className="purchase-step container px-md-4 py-2 bg-white" >
      <div className="purchase-step-title p-md-3 p-2 py-3 d-flex align-items-center">
        <Badge active={active} completed={completed} content={id} /> <span className="fs-18">
          {title}
        </span>
      </div>
      <div id={`collapse${id}`} className={`accordion-collapse collapse show`} aria-labelledby={`heading${id}`} data-bs-parent="#accordionExample">
        <div className="accordion-body">
          {children}
        </div>
      </div>
    </div>
  );
}

