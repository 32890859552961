import { AuthUser } from '@/features/auth/types';
import { create } from 'zustand';

type PaymentFlow = {
  otpToken?: string
  current_step: "get_otp" | "otp_verification" | "payment"
  full_phone_number?: string
  authUser?: AuthUser
  redirectUrl?: string
  token?: string | undefined
}

type PaymentFlowStore = {
  otpToken?: string
  current_step: "get_otp" | "otp_verification" | "payment"
  full_phone_number?: string
  authUser?: AuthUser
  token?: string | undefined
  setState: (new_state: PaymentFlow) => void
};

export const usePaymentFlowStore = create<PaymentFlowStore>((set) => ({
  current_step: "get_otp",
  setState: (new_state) =>
    set((state) => ({ ...state, ...new_state })),
}));
