import React from 'react';

const DeckTimeFilter = ({ data, selectedTime, setSelectedTime }: any) => {
    return (
        <div className='date-filter-container mx-2 py-1 py-md-0'>
            {data?.map((item: any) => {
                const isSelected = item === selectedTime;
                return (
                    <div
                        key={item}
                        onClick={() => setSelectedTime(item)}
                        className={`date-filter-btn ${isSelected ? 'date-filter-btn-selected' : ''}`}
                    >
                        {item}
                    </div>
                );
            })}
        </div>
    );
};

export default DeckTimeFilter;