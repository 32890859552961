import { axios } from '@/lib/axios';
import { PurchaseResponse } from '../types';

type PurchaseDTO = {
    coupon_code: string
    channel_package_id: string
}
export const purchaseApi = (data: PurchaseDTO, token: string): Promise<PurchaseResponse> => {
    return axios.post(`/discounts/apply_discount`, data, {
        headers: {
            "Authorization": `${token}`
        }
    });
};
