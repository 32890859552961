import LogoSvg from '@/assets/images/logo.svg'
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
export interface IHeaderProps {
  backNav?: boolean
  redeemPaymentPage?: boolean
}

export const Header = ({ backNav = true, redeemPaymentPage }: IHeaderProps) => {

  const navigate = useNavigate();

  let isPaymentPage = window.location.pathname.includes('/purchase');

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-white shadow-sm p-1 ${isPaymentPage ? 'd-md-none' : 'd-md-block'}`}>
      {redeemPaymentPage ? (
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={LogoSvg} alt="Logo" className='d-none d-md-block' />
              <img src={LogoSvg} alt="Logo" className='d-md-none' width={94} height={36} />
            </a>
          </div>
      ) :  isMobile ? (
        <div className="container">
          {backNav && <i className="bi bi-arrow-left fs-40" onClick={() => { navigate(-1) }} ></i>}
          <a className="navbar-brand m-auto m-md-0" href="/">
            <img src={LogoSvg} alt="Logo" className='d-none d-md-block' />
            {!isPaymentPage ? <img src={LogoSvg} alt="Logo" className='d-md-none' width={94} height={36} /> : null}
          </a>
        </div>
      ) : (
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LogoSvg} alt="Logo" className='d-none d-md-block' />
            <img src={LogoSvg} alt="Logo" className='d-md-none' width={94} height={36} />
          </a>
        </div>
      )}
    </nav>
  );
}

