import { Header } from '@/components/Elements';
import * as React from 'react';



export function RefundPolicy() {
  return (
    <>
      <Header />
      <div className="container py-3">
        <div className="row">
          <div className="col-12">
            <h2>REFUNDS AND CANCELLATION POLICY</h2>
            <p >Posted as of September 2023</p>
            <p >Last updated as of September 2023</p>
            <p >&nbsp;</p>
            <p >Welcome to OpiGo&rsquo;s Refund and Cancellation Policy</p>
            <p >The following Refund and cancellation Policy describes how, OpiGo (owned by M/s Ijooloo Private Limited and affiliates, &ldquo;we, &ldquo;us&rdquo; or &ldquo;our&rdquo;) process refund and return requests, that we obtain through&nbsp;<a href="http://www.opigo.in/"><span className="s1">www.opigo.in</span></a></p>
            <p >&nbsp;</p>
            <ol className="ol1">
              <li className="li1">REFUND REQUESTS</li>
            </ol>
            <p >Our Website does not entertain any refund requests. Once a payment has been made by a User, and they choose not to participate for whatever reason, no amount of the payment will be refunded.</p>
            <p >All payments are made via payment gateways, Users are advised to read all information carefully before making a payment. We will not be held liable for any payment discrepancies.</p>
            <p >&nbsp;</p>
            <ol className="ol1">
              <li className="li1">CANCELLATION REQUESTS</li>
            </ol>
            <p >Our Website does not entertain any cancellation requests. Users can choose not to participate but We do not have any provision for cancellation of a participant.</p>
            <p >We maintain a negative list of all fraudulent transactions and non-complying users and reserve the right to deny access to such users at any time or cancel any orders placed by them in future.</p>
          </div>
        </div>
      </div>
    </>
  );
}

