const isWindows = () => {
  return /Win/.test(window.navigator.userAgent);
}

const getOS = () => {
  const userAgent = window.navigator.userAgent;
  let os = 'Unknown';

  if (/Android/i.test(userAgent)) {
    os = 'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !isWindows()) {
    os = 'iOS';
  } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    os = 'Mac OS';
  } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    os = 'Windows';
  } else if (/Linux/.test(userAgent)) {
    os = 'Linux';
  }

  return os;
}

export const getAppURL = () => {
  const os = getOS();
  if (os === 'iOS') {
    return "https://apps.apple.com/in/app/opigo-stock-market-strategies/id1619955231"
  } else if (os === 'Android') {
    return "https://play.google.com/store/apps/details?id=com.OpiGo1final"
  }

  return 'https://apps.apple.com/in/app/opigo-stock-market-strategies/id1619955231'
}