import { OTPInput } from '@/components/Elements';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PurchaseStep } from './PurchaseStep';
import { usePaymentFlowStore } from '../stores/payment_flow';
import { onboard, onboardVerify } from '@/features/auth/api/onboard';
import { toast } from 'react-toastify'
export function OtpVerificationStep() {

  const { otpToken, setState, current_step, full_phone_number } = usePaymentFlowStore();
  const [loading, setLoading] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [timer, setTimer] = useState(0)
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  const otpRef = useRef<any>(null)

  const [{ otp, numInputs, separator, minLength, maxLength, placeholder, inputType }, setConfig] = useState({
    otp: '',
    numInputs: 4,
    separator: '',
    minLength: 0,
    maxLength: 40,
    placeholder: '',
    inputType: 'tel' as const,
  });

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback])

  useEffect(() => {
    if (current_step == "otp_verification") {
      otpRef?.current?.scrollIntoView(true)
      setTimer(30)
      if(otp){
        handleOTPChange('')
      }
    }
  }, [current_step])

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  const handleOTPChange = (otp: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
  }

  const submitOtp = async () => {
    setLoading(true)
    if (!otpToken || !full_phone_number) {
      setState({ current_step: 'get_otp' })
      toast("Please provide your Whatsapp number first", { theme: 'colored', type: 'error' })
      setLoading(false)
      return;
    }
    if (otp.length != 4) {
      toast("Please provide valid 4 digit otp", { type: 'error', theme: 'colored' })
      setLoading(false)
      return
    }
    const result = await onboardVerify({ account: { token: otpToken, otp: otp } })
    if (result.authorization) {
      setState({
        current_step: 'payment',
        token: result.authorization
      })
    }
    setLoading(false)
  }

  const reSubmitOtp = async () => {
    setRetryCount(retryCount + 1)
    if (retryCount > 2) {
      toast("You have reached the maximum retry count, please try again later", {
        type: "error",
        theme: "colored"
      })
      return
    }
    resetTimer()
    const result = await onboard({ account: { full_phone_number: full_phone_number!, message_type: 'whatsapp'} })
    toast("OTP has been resent to your mobile", {
      type: "info",
      theme: "colored"
    })
    setState({
      otpToken: result.token,
      current_step: "otp_verification"
    })
  }

  return (
    <PurchaseStep active={current_step == "otp_verification"} completed={otp.length == 4 || current_step == "payment"} id={2} title={'OTP Verification'}>
      {current_step == "otp_verification" && (
        <div className="mb-3 col col-md-6" ref={otpRef} >
          <OTPInput
            numInputs={numInputs}
            onChange={handleOTPChange}
            renderSeparator={<span>{separator}</span>}
            value={otp}
            placeholder={placeholder}
            inputType={inputType}
            renderInput={(props) => <input {...props} className={`form-control text-center p-3 mx-2 rounded-2 ${props.value && 'otp-input'}`} type={"tel"} disabled={!full_phone_number} />}
            skipDefaultStyles
          />
          {current_step == "otp_verification" && full_phone_number && (
            <div className='d-flex align-items-center mt-4' >
              <p className="fs-14 me-3">Didn’t receive the OTP? </p>
              {timer > 0 && (<b>Retry in {timer}</b>)}
              {!timer && (<span className='btn btn-sm btn-success' onClick={reSubmitOtp} >Resend Otp</span>)}
            </div>
          )}
          <div className="d-grid my-3">
            <button className="btn btn-primary rounded-2 p-2" disabled={loading || !full_phone_number} onClick={submitOtp} >{loading ? 'Loading...' : 'Submit'}</button>
          </div>
        </div>
      )}

    </PurchaseStep>
  );
}

