import React from 'react'

interface FeatureCardProps {
    image: string;
    title: string;
    description: string;
    color: string;
}

const FeatureCard = ({ image, title, description, color }: FeatureCardProps) => {

    const backgroundColorOnHover = hexToRgba(color, 2);

    return (
        <>
            <div className="ml-2 mr-2 padding-col">
                <div className={`content-col active`} style={{ "--feature-card-border-color": color, "--feature-card-background-color": backgroundColorOnHover } as React.CSSProperties}>
                    <div className="flex-container">
                        <div className="img-box">
                            <img src={image} alt={title} />
                        </div>
                        <h2 className="card-title">{title}</h2>
                    </div>
                    <p className="card-para">{description}</p>
                </div>
            </div>
        </>
    )
}

function hexToRgba(hex: string, opacity: number): string {
    hex = hex.replace('#', '');

    // Parse the hex values for red, green, blue
    const r: number = parseInt(hex.substring(0, 2), 16);
    const g: number = parseInt(hex.substring(2, 4), 16);
    const b: number = parseInt(hex.substring(4, 6), 16);

    const alpha: number = opacity / 100;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default FeatureCard