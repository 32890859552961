import React from 'react'

interface DeckCardProps {
    image: string;
    name: string;
    experience: string;
    info: string;
    data: any;
}

const DeckCard = ({ image, name, experience, info, data }: DeckCardProps) => {
    
    return (
        <>
            <div className= "bg-white rounded-3 shadow-sm p-2 p-md-4 m-2 mx-3 my-2" style={{maxWidth: '457px'}}>
                <div className="d-flex align-items-center">
                    <img src={image} alt="Person" width={38} height={38} style={{ objectFit: 'cover' }} className='rounded-circle' />
                    <div className='ms-3' >
                        <p className="fs-14 fw-medium">{name}</p>
                        <p className="fs-10">{experience}</p>
                    </div>
                </div>
                <div className="fs-14 text-green fw-medium mt-3 w-auto rounded-2 d-inline-block px-2 p-1" style={{ background: "#D3ECED" }} >
                    {info}
                </div>
                <hr />
                <div className="row">
                    {
                        data?.map((item: any) => {
                            return (
                                <div className='col-6 d-flex align-items-center my-2' key={item.info} >
                                    <img src={item.image} alt="Stock name" style={{ height: '50px', width: '50px' }} />
                                    <div className='ms-2' >
                                        <p className='fs-14 fw-bold' style={{ color: "#575454" }} >{item.title}</p>
                                        <p className="fs-12">{item.info}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default DeckCard