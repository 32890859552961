import { Header } from '@/components/Elements';
import * as React from 'react';



export function TermsOfUse() {
  return (
    <>
      <Header />
      <div className="container py-3">
        <div className="row">
          <div className="col-12">
            <h2>Terms Of Use</h2>
            <h4 >Introduction</h4>
            <p ><br /></p>
            <p >Ijooloo Private Limited having CIN U72900MH2021PTC364498 (hereinafter referred as ‘OpiGo’) is a technology platform where the views and opinions on listed stocks are published by research analysts (referred to as Experts) registered with the Securities and Exchange Board of India (SEBI) as well as by unregistered persons / entities.</p>
            <p ><br /></p>
            <p >User means and includes a visitor on the website <a href="http://www.opigo.in/" className="s2" target="_blank">https://www.opigo.in/</a>, its sub domains / microsites (collectively known as the Website) and the Mobile App named ‘OpiGo’ (referred to as the Mobile App) who uses the Website and the Mobile App to publish its views.</p>
            <p ><br /></p>
            <p >Experts are verified Users with the required qualification and license as per the relevant SEBI regulations. A verified sign is placed against each Expert’s name.</p>
            <p ><br /></p>
            <p >The Experts and the Users publish their views on a particular stock on the Website and the Mobile App, referred to as “Cards”.</p>
            <p ><br /></p>
            <p >Based on the categories established by OpiGo internally, the Cards published only by the Experts are bundled to create a list of stocks known as a “Deck”. Any user can make a payment to OpiGo and subscribe to the Decks created by OpiGo.</p>
            <p ><br /></p>
            <p >OpiGo is not registered with SEBI in any capacity and is not providing any recommendation or advisory services. Opigo is providing technology support to the Users and Experts to showcase their opinion on listed stocks. OpiGo is not promoting any of the Experts and the Users. The views expressed by the Experts and the Users are on an “as is” basis and are neither validated or approved by OpiGo.</p>
            <p ><br /></p>
            <p >For the purpose of these terms (Terms and Conditions), “OpiGo” includes the directors, the employees, representatives, agents and associates.</p>
            <p ><br /></p>
            <h4 >Terms and Conditions</h4><p ><br /></p>
            <p >These Terms and Conditions mandate the terms on which any visitor including any User and the Expert, accesses and registers on the Website and the Mobile App.</p>
            <p ><br /></p>
            <p >The Users are advised to read these terms and conditions carefully before using the Website and the Mobile App or availing any services from the OpiGo or the Experts. By visiting the Website or downloading the Mobile App, the user agrees to be bound by these terms and conditions. Subject to these terms, OpiGo hereby grants the User the permission to use and access this Website and the Mobile App.</p>
            <p ><br /></p>
            <ol id="l1"><li data-list-text="1."><h4 >Terms for Publishing Cards</h4><p ><br /></p>
              <ol id="l2"><li data-list-text="a."><p>The Users shall publish Cards to test their market skills and track the performance of the Cards.</p>
              </li><li data-list-text="b."><p >The Experts and the Users shall, at their own discretion, publish Cards in their own account as per their own understanding of the stock market and their own research methodology.</p>
                </li><li data-list-text="c."><p >It will be the discretion of OpiGo to showcase all or any of the Cards published by the Experts and Users.</p>
                </li><li data-list-text="d."><p >OpiGo is not a research expert and does not validate the views or content published by the Experts or Users in the Cards.</p>
                </li><li data-list-text="e."><p >The categories of the Decks will be decided by OpiGo, internally. However, the Experts, will as per own discretion and understanding of the companies tag the Card to a particular category established by OpiGo.</p>
                </li><li data-list-text="f."><p >OpiGo will not be under any obligation to check and verify if the Cards have been tagged to the correct category.</p>
                </li><li data-list-text="g."><p >OpiGo will not be liable or responsible for any loss or liability incurred by any User due to non-publishing of any Card or due to taking or not taking any action based on the Cards in the Deck.</p>
                </li><li data-list-text="h."><p >Past performance does not guarantee future performance or returns.</p>
                </li><li data-list-text="i."><p >Though the stock prices mentioned in the Cards are updated on real-time basis, the data is not validated by OpiGo.</p>
                  <p ><br /></p>
                </li></ol></li><li data-list-text="2."><h4 >Terms for posting comments and information</h4><p ><br /></p>
                <ol id="l3"><li data-list-text="a."><p >The views, opinions and comments posted are of the Expert and the User, and are not endorsed or verified by OpiGo, in any manner.</p>
                </li><li data-list-text="b."><p >The user shall be solely responsible for the comment(s) posted on OpiGo. Comments posted on OpiGo may be moderated and OpiGo reserves the right to edit, modify, delete, reject, or otherwise remove any views, opinions and comments posted or part thereof without notice.</p>
                  </li><li data-list-text="c."><p >The user shall ensure that the views, information and comments posted by the users are not inflammatory, abusive, derogatory, defamatory &amp;/or obscene, or contain pornographic matter and/or does not constitute hate mail, or violate privacy of any person (s) or breach confidentiality or otherwise is illegal, immoral or contrary to public policy. Nor should it contain anything infringing copyright &amp;/or intellectual property rights of any person(s).</p>
                  </li><li data-list-text="d."><p >OpiGo does not guarantee the accuracy, integrity or quality of any content posted on OpiGo.</p>
                  </li><li data-list-text="e."><p >The users agree to not send, submit, post, or otherwise transmit, messages or material that contain software virus, that are designed to interrupt, destroy, and negatively affect in any manner whatsoever, any electronic equipment / software / coding in connection with the use of this website and the Mobile App.</p>
                  </li><li data-list-text="f."><p >In the event that the user breaches any of the Terms and Conditions, OpiGo reserves the right to delete any material relating to the violations without notice. OpiGo also reserves the right to ban anyone who wilfully violates these Terms and Conditions. Inobservance of these terms and conditions may lead to an immediate warning, an infraction and deletion of the concerned post. Repeated offences shall lead to the user being permanently banned from OpiGo.</p>
                  </li></ol></li><li data-list-text="3."><h4 >General Terms for Use of Website and the Mobile App</h4><p ><br /></p>
                <ol id="l4"><li data-list-text="a."><p >On registering with OpiGo or its the Mobile App, the user agrees that all the information provided by the user now or in the future is true and correct.</p>
                </li><li data-list-text="b."><p >The user agrees that the user will not copy, reproduce, sell, redistribute, display, modify, enter into any database, alter, create derivative, transfer or in any way exploit any part of any information, content, material or any piece of information available through the Website.</p>
                  </li><li data-list-text="c."><p >The user agrees to not impersonate or misrepresent itself as another person (including celebrities) entity, or other user or employee or representative of OpiGo.</p>
                  </li><li data-list-text="d."><p >On signing up with OpiGo, the user grants OpiGo the permission to use all and any version of the logo used by the user, including but not limiting to names, trademark, service marks or logos for the purpose of marketing and promotional activities.</p>
                  </li><li data-list-text="e."><p >The user agrees that it will not use the Website and the Mobile App in manner or conduct itself in any way which can violate the legal rights of OpiGo and other users and hamper their use of the Website and the Mobile App.</p>
                  </li><li data-list-text="f."><p >Any action taken or not taken based on any notification or alert received from OpiGo will be at user’s own discretion and for user’s own benefit.</p>
                  </li><li data-list-text="g."><p >Any information rendered through the Website and the Mobile App should not be considered investment advice or recommendation from OpiGo. In case of any loss or damage incurred to the user, OpiGo will not be responsible and / or held liable. The User warrants that he/she/it shall obtain independent investment advice before making any Investment based on data published on OpiGo.</p>
                  </li><li data-list-text="h."><p >The price details, returns, and any other data mentioned on the website are received from various third-party data vendors. The data is not validated by OpiGo.</p>
                  </li><li data-list-text="i."><p >The recommendations / views / reports and other contents, including third party content, are provided on an &quot;As Is&quot; basis by OpiGo. Use of the Website and the content is at any User’s own risk and OpiGo will not be liable and responsible in any manner.</p>
                  </li><li data-list-text="j."><p >Information herein is believed to be reliable but OpiGo does not warrant its completeness or accuracy and shall not be responsible or liable for any losses incurred by a user for acting based on the views expressed herein.</p>
                  </li><li data-list-text="k."><p >Users should note that investments in stock markets are subject to market risks and should consult an investment adviser before investing or trading based on the recommendations given by Experts on OpiGo.</p>
                  </li><li data-list-text="l."><p >Market prices and estimates shown on the Website or the Mobile App may be delayed and OpiGo will not be liable or responsible for loss or liability arising to the user on account of the delay.</p>
                  </li><li data-list-text="m."><p >OpiGo reserves the right to discontinue, whether on a temporary or permanent basis, any of the features available on Website and the Mobile App.</p>
                  </li><li data-list-text="n."><p >The Experts listed on the Website and the Mobile App would share the views at their own discretion.</p>
                  </li><li data-list-text="o."><p >Experts and Users may have a different or contracting views on a particular stock. The views expressed by the Users should be considered are just as reference and for learning  purpose.  In  case  any  person  needs  more  detailed  research/</p>
                    <p >recommendations/ views the users are advised to reach out to the Experts listed on the Website or the Mobile App.</p>
                    <p ><br /></p>
                  </li></ol></li><li data-list-text="4."><h4 >Terms for scores assigned to the Users</h4><p ><br /></p>
                <p >The Users are given scores based on the performance of their Cards. The scores are updated on real-time basis. The gains and losses generated with cards are taken into account in OpiGo’s algo-based calculation to derive the profile score. The scores are given to the user just for reference. Users should not use scores to promote themselves. A higher score does not make any User an Expert. OpiGo does not warrant accuracy of the scores and will not be liable or responsible to any User for any loss or liability incurred by the User due to the action taken or not taken by any visitor or User.</p>
                <p ><br /></p>
              </li><li data-list-text="5."><h4 >Modification of Terms and Conditions</h4><p ><br /></p>
                <ol id="l5"><li data-list-text="a."><p >OpiGo reserves the right to update the Terms and Conditions at any time, with or without notice.</p>
                </li><li data-list-text="b."><p >The continual use of Website after the update will be considered as deemed consent by the user, and they cannot claim any liability from OpiGo.</p>
                  </li><li data-list-text="c."><p >Users agree to review these terms of use periodically; subsequent use by the users of this site shall constitute their acceptance of any changes. OpiGo shall have the right at any time to change or discontinue any aspect of the Website, the Mobile App, including, but not limited to, the community areas, content and equipment needed for access to use. Such changes, modifications, additions or deletions shall be effective immediately upon posting or updating and any subsequent use by the user after such posting shall conclusively be deemed to be acceptance of such changes, modifications or deletions.</p>
                    <p ><br /></p>
                  </li></ol></li><li data-list-text="6."><h4 >Limits on Personal and Non-Commercial Use</h4><p ><br /></p>
                <p >The User expressly agrees to use the service strictly for personal purpose and at his own discretion and under no obligation to use the services. The User shall not recompile, disassemble, copy, modify, distribute, transmit, perform, reproduce, publish or create derivative works from, transfer, or sell any information, software, products, tools or services accessed from the Website and the Mobile App. The User may not rent, lease, sell, sublicense, lend or in any manner allow any other party to use the service, with or without consideration. By viewing the contents of the Website and the Mobile App, the user agrees with this condition of viewing and the user acknowledges that any unauthorised use is unlawful and may be subject to civil or criminal penalties.</p>
                <p ><br /></p>
              </li><li data-list-text="7."><h4 >Indemnification</h4><p ><br /></p>
                <p >The User agrees to indemnify, defend and hold harmless OpiGo, its officers, directors, employees, associates, representatives and agents, any third party providers, distributors</p>
                <p >from and against any cause of action, claim or demand, including without limitation any reasonable legal accounting or other professional fees, brought by or on the user&#39;s behalf in excess of the liability described herein or by / on account of a third party due to or arising out of user&#39;s use of this Website and the Mobile App, the service contained herein, views expressed herein, the violation of any intellectual property or any other right of any person or entity.</p>
                <p >This clause will survive the termination or expiration of terms and conditions or termination of usage of the user of the website or the Mobile App.</p>
                <p ><br /></p>
              </li><li data-list-text="8."><h4 >Limitations of liability</h4><p ><br /></p>
                <p >OpiGo will not be liable to the user (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this Website:</p>
                <ul id="l6"><li data-list-text="●"><p >for any direct, indirect, special or consequential loss; or</p>
                </li><li data-list-text="●"><p >for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</p>
                  </li><li data-list-text="●"><p >for any direct or indirect loss or liability incurred due to transactions placed on the Website or the Mobile App and due to any error or omission or interruption on any third-party website or third-party product</p>
                  </li></ul><p ><br /></p>
              </li><li data-list-text="9."><h4 >Objectionable Information</h4><p ><br /></p>
                <p ><a href="mailto:help@opigo.in" className="a" target="_blank">While using the services of OpiGo, if any user comes across any content or comment that is abusive in nature or violating the Terms and Conditions or if the user becomes aware of any objectionable content on the Website and the Mobile App, please report the same by sending an email with the all the necessary details to </a><a href="mailto:help@opigo.in" target="_blank">help@opigo.in</a></p>
                <p ><br /></p>
              </li><li data-list-text="10."><h4 >Complaint</h4><p ><br /></p>
                <p ><a href="mailto:help@opigo.in" className="a" target="_blank">In case of any grievance with respect to the Website or the services, including any discrepancies with respect to processing of information or display of information, the user can email us at </a>help@opigo.in and inform us about the same along with the documents and necessary action will be taken in due course. The decision of OpiGo will be final and binding on the user.</p>
                <p ><br /></p>
                <p >OpiGo will not be responsible for any dispute from third party products or services, including services from the broker or any Expert, and the same will be handled and resolved by such third party.</p>
                <p ><br /></p>
              </li><li data-list-text="11."><h4 >Unsystematic Risk</h4><p ><br /></p>
                <p >The information is provided by third parties and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability</p>
                <p >with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance placed on such information is therefore strictly at the user’s own risk. OpiGo will not be responsible for any delay or failure while complying with our obligations under the Agreement, if the delay or failure arises from any cause which is beyond our control, including but not limited to acts of God, acts of any governmental authority, and any virus or security breach to OpiGo’s online facilities through which the Services are rendered.</p>
                <p ><br /></p>
              </li><li data-list-text="12."><h4 >Under Indian Judiciary</h4><p ><br /></p>
                <p >This Agreement entered into and the relationship between the user and OpiGo shall be governed in accordance with the laws of India without reference to any conflict of laws principles.</p>
                <p ><br /></p>
              </li><li data-list-text="13."><h4 >Intellectual Property Rights (IP)</h4><p ><br /></p>
                <p >OpiGo hereby grants to the user, the right to use the Service in accordance with these terms, and for no other purpose. Please note that all elements contained on this Web-Site are owned by OpiGo and are protected by Copyright, Service Marks and Trademark laws, and/or other proprietary rights under the laws of India and these Terms of Use. The content published by the Experts will be owned by the Experts only. By using the service, the users agree to abide by all applicable Copyright and other laws. OpiGo or other third parties shall be entitled to obtain equitable relief for any violation of their IP, over and above all other remedies available to it to protect its interests therein. Copying for reproduction, for redistribution or other purpose of the web site, the Mobile App or any part thereof including but not limited to text content and graphics to any other server or location including caching of any kind, Decompiling, reverse engineering, or disassembling the content is expressly prohibited. OpiGo owns its applications and Trademarks.</p>
                <p ><br /></p>
              </li><li data-list-text="14."><h4 >Cookies</h4><p ><br /></p>
                <p >Cookies are information that the browser collects after visiting a website. We may use cookies for technical purposes and to enhance the navigation and working of the website. We may also use them to store user preferences while interacting with our website. The users have the option to turn off cookies on the browser but it can affect the browsing experience while using the Website.</p>
                <p ><br /></p>
              </li><li data-list-text="15."><h4 >Links to third Party Sites</h4><p ><br /></p>
                <p >This Website and the Mobile App may contain links to websites operated by parties other than OpiGo. The links in this Website will let the user proceed to the third-party website. The user&#39;s use of each such website is also subject to these Terms and Conditions and other terms of use, if any, contained within each such website. In the event that any of the terms contained herein conflict with the terms of use contained within any such websites, then the terms of use for such websites shall prevail. The third-party websites are not under the control of OpiGo and OpiGo is not responsible for the contents of any third-party website or</p>
                <p >any changes or updates to such websites. OpiGo will not directly or indirectly be liable for any loss that may arise to any user as a result of his accessing the linked sites.</p>
                <p ><br /></p>
              </li><li data-list-text="16."><h4 >Termination rights and policy</h4><p ><br /></p>
                <p >These terms and conditions shall remain valid and active until terminated or cancelled by OpiGo for any or the reasons mentioned below but not limited to:</p>
                <ol id="l7"><li data-list-text="a."><p >Breach of agreement by the user.</p>
                  <ol id="l8"><li data-list-text="a."><p >Unauthorised access granted to an unregistered person or unauthorised use by the user.</p>
                  </li></ol><ol id="l9"><li data-list-text="a."><p >The user chooses to discontinue the account with OpiGo.</p>
                  </li></ol><ol id="l10"><li data-list-text="a."><p >If the user attempts to transfer any of the rights granted under these terms to a person unregistered with OpiGo.</p>
                  </li></ol><p ><br /></p>
                  <p >OpiGo can blacklist any user or block access it is of the opinion that the user has infringed, breached, violated, abused or unethically manipulated these terms of use or acted unethically on any occasion. However, the data given by the user will be preserved by the OpiGo and can be further appropriated. The data can be used as evidence in case of any civil or criminal liability against the OpiGo.</p>
                  <p ><br /></p>
                </li></ol></li><li data-list-text="17."><h4 >Privacy</h4><p ><br /></p>
                <p >The user represents that he is aware that in the process of using the website or parts of it or subscribing to any services offered by OpiGo, OpiGo may obtain information relating to the user, including that of a confidential nature.</p>
                <p ><br /></p>
                <p >OpiGo may for the purpose of rendering its services may collect personal information such as including email address or other contact information, PAN, signature and photograph, Bank account or other payment instrument details; or any other detail for providing services. OpiGo can use the information provided by the user at its own discretion, while disseminating it for any other purpose as OpiGo deems appropriate.</p>
                <p ><br /></p>
                <p >This information provided by the Users will be used by OpiGo for its internal purposes as well as external purposes. The User agrees to permit OpiGo to share data to the third parties to provide services to the User. Notwithstanding anything contained above, OpiGo reserves the right to disclose personal information where it believes in good faith that such disclosure is required by law, to perform necessary credit checks or to protect OpiGo&#39; rights or property or for other bonafide uses. The user agrees and warrants that all information that OpiGo may possess or may obtain pursuant to the use of the Website and services has been possessed or obtained with the permission of the user.</p>
                <p ><br /></p>
                <p >User shall bear all responsibility of keeping the password secure and in the event that the User incurs any liability to OpiGo or any other third party as a result of the password being used by third parties, or due to improper use of the password, User shall, without limitation, bear all such liability. Notwithstanding anything contained herein, in the event any liability</p>
                <p >arising to the User as a consequence of any of OpiGo&#39; employees accessing the User&#39;s password without authorization shall be borne by the User.</p>
                <p ><br /></p>
                <p ><a href="http://www.opigo.in/privacypolicy" className="a" target="_blank">https://</a><a href="http://www.opigo.in/privacypolicy" target="_blank">www.opigo.in/privacypolicy</a></p>
                <p ><br /></p>
              </li><li data-list-text="18."><h4 >Disclaimer of warranties:</h4><p ><br /></p>
                <p >OpiGo expressly disclaims all warranties and conditions of any kind, whether express, implied or statutory pertaining to the services provided by it including but not limited to implied warranties and conditions of merchantability, fitness for a particular purpose, data accuracy and completeness and any warranties relating to viruses and services free from disruption or interruption. OpiGo does not guarantee suitability of the services in any manner to any User.</p>
                <p ><br /></p>
                <p >OpiGo shall not be responsible (directly or indirectly) for use or otherwise of Service by a User, including a User who is a citizen or resident or located in any locality, state, country or other jurisdiction outside India specifically a resident of the United States of America, Canada or the European Union countries and we hereby expressly disclaim any implied warranties imputed by the laws of any other jurisdiction.</p>
                <p ><br /></p>
                <p >OpiGo shall also not be liable for errors, omissions or typographical errors, disruption delay, interruption, failure, deletion or defect of/in the services provided by it.</p>
                <p ><br /></p>
                <p >OpiGo does not warrant accuracy of any feed from the stock exchanges or any other third party.</p>
                <p ><br /></p>
                <p >OpiGo may at any time without notice withdraw the facility of sending alerts to the subscribers. In case any subscriber does not want to receive alerts from OpiGo, he/she may unsubscribe or send a written intimation to OpiGo on the same.</p>
                <p ><br /></p>
                <p >The website assumes no responsibility for damage to computers or software of the user from corrupting code or data that is inadvertently passed to the user’s computer. Again, the user views and interacts with this website, or banners or pop-ups or advertising displayed thereon, at his own risk.</p>
                <p ><br /></p>
              </li><li data-list-text="19."><h4 >Disclaimer for Performance Calculation</h4><p ><br /></p>
                <p >Calculation of data is based on assumptions integral to the model which may or may not be testable and are therefore subject to losses. Actual performance may differ from performance calculated by OpiGo. Returns calculated do not represent actual returns and should not be interpreted as an indication of such. OpiGo will not be liable or responsible for any loss or liability incurred by the user on account of any decisions taken on the basis of back testing results.</p>
                <p ><br /></p>
              </li><li data-list-text="20."><h4 >Community of users:</h4><ol id="l11"><li data-list-text="a."><p >OpiGo reserves the right to create any community or give the option to Expert to create one. However, the community will be controlled, monitored and administered by OpiGo.</p>
                <p ><br /></p>
              </li><li data-list-text="b."><p >OpiGo will determine, at its sole discretion, the terms of participation in the community as well as whether the content posted is in compliance with these terms.</p>
                  <p ><br /></p>
                </li><li data-list-text="c."><p >The members (users) of the community as well as the Experts shall not challenge the decision or any such action of OpiGo. Any action of OpiGo should not be construed as defamatory.</p>
                  <p ><br /></p>
                </li><li data-list-text="d."><p >The community shall be used only to communicate data and information about capital markets and securities as permissible under the applicable law. The views posted by any member of the community or user or Expert will be their personal views and not the views of the entire community or of OpiGo.</p>
                  <p ><br /></p>
                </li><li data-list-text="e."><p >The community should not be used for circulating, uploading, displaying or transmitting any content, data or information that is unlawful, threatening, abusive, vulgar, hateful, invasive of other’s privacy, unethical, offensive or objectionable or for illegal or unlawful activities.</p>
                  <p ><br /></p>
                </li><li data-list-text="f."><p >The members should not harass, threaten or harm other members in the community.</p>
                  <p ><br /></p>
                </li><li data-list-text="g."><p >The community should not be used to post or share any unsolicited or unauthorised messages, promotional materials, junk emails, jokes, poems, spam, any advertising announcements, any religious or political views.</p>
                  <p ><br /></p>
                </li><li data-list-text="h."><p >The members should not send, submit, post, or otherwise transmit, messages or material that contain software virus, that are designed to interrupt, destroy, and negatively affect in any manner whatsoever, any electronic equipment / software / coding or any other computer software or hardware.</p>
                  <p ><br /></p>
                </li></ol></li><li data-list-text="21."><h4 >Refund Policy</h4></li></ol><p ><br /></p>
            <p >OpiGo may decide to increase/decrease or in any way modify the fee payable for the service. The user agrees to be bound by them.</p>
            <p ><br /></p>
            <p ><a href="/refund_policy">Refund Policy</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

