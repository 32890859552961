import { axios } from '@/lib/axios';
import { AuthUser, OnboardResponse } from '../types';

export type OnboardDTO = {
  account: {
    full_phone_number: string
    message_type: string
  }
};

export type OnboardVerifyDTO = {
  account: {
    otp: string
    token: string
  }
}

export const onboard = (data: OnboardDTO): Promise<OnboardResponse> => {
  return axios.post('/accounts/onboard', data);
};

export const onboardVerify = (data: OnboardVerifyDTO): Promise<AuthUser> => {
  return axios.post('/accounts/onboard_verify', data);
};
