import Axios from 'axios';

import { API_URL, API_KEY } from '@/config';
import storage from '@/utils/storage';
import { toast } from 'react-toastify';

export const axios = Axios.create({
  baseURL: API_URL,
  // baseURL: "https://staging.opigo.co.in/api/v1"
});

axios.interceptors.request.use(function (config) {
  const token = storage.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["X-Opigo-Api-Key"] = API_KEY
  // config.headers["X-Opigo-Api-Key"] = "f9a8ef84e8bc7a9d1ea8519e0edc765d"
  config.headers.Accept = 'application/json';
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    //@ts-ignore
    if(window.isRedeem){
      return true;
    }
    if(error?.response?.data?.error === 'Oops! It seems like the coupon code you entered is not valid. Please double-check the code and try again.'){return }
    if (error?.response?.data?.code == 401 || error?.response?.data?.message == "JWT Decode Error") {
      toast("You are not authorized, please login and try again", { theme: 'colored', type: 'warning' })
      storage.clearToken()
      // window.location.reload()
    } else if (error?.response?.data?.message) {
      toast(error?.response?.data?.message, { theme: 'colored', type: 'error' })
    } else {
      toast("Something went wrong please try again later :( ", { theme: 'colored', type: 'error' })
    }

    return Promise.resolve(error);
  }
);
