import React from 'react'

interface TesttimonialProps {
    image: string,
    description: string,
    userName: string,
    userStatus: string,
    index: number,
}

const TestimonialCard = (props: TesttimonialProps) => {
    return (
        <>
            <div className="main-col d-flex justify-content-center justify-content-md-start">
                <div className="liner-box" style={{ backgroundImage: getBackgroundImage(props.index) }} ></div>
                <div className="card-col">
                    <div className="content-box">
                        <p className="main-para">{props.description}</p>
                    </div>
                    <div className="user">
                        <div className="flex-container">
                            <div className="img-box">
                                <img src={props.image} alt="User Profile Image" />
                            </div>
                            <div className="user-info">
                                <h1 className="name">{props.userName}</h1>
                                <div className="ratting">
                                    <div className="flex-container">
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

function getBackgroundImage(index: number) {
    let color1 = "";
    let color2 = "";

    switch (index % 3) {
        case 0:
            color1 = "#F1A046";
            color2 = "#EA4663";
            break;
        case 1:
            color1 = "#6565D5";
            color2 = "#C55DD1";
            break;
        case 2:
            color1 = "#E94596";
            color2 = "#B76FEE";
            break;
    }

    return `linear-gradient(to right, ${color1}, ${color2})`;
}

export default TestimonialCard;
