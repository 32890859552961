import React from 'react';
import '../../../styles/rewardsCongratulation.scss';
import {useLocation, useNavigate} from "react-router-dom";

const RewardsCongratulation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { name, images } = location.state || {};

    const returnToHome = () => {
        navigate('/redeem-rewards');
    };

    return (
    <div className="congratulation-rewards-main">
        <div className="congratulation-container">
        <div className="coupon-success-container">
                  <div>
                      <h1 className="congrats-heading">Congratulations!</h1>
                      <p className="sub-heading">Check your coupon</p>
                  </div>

               <div>
                   <img
                       src={images?.mobile || ''}
                       alt={images?.mobile || ''}
                       className="coupon-image"
                   />
                   <h2 className="coupon-company-name">{name || ''}</h2>
                   <p className="coupon-message">
                       Congratulations for purchasing a coupon. Your Coupon has been sent to your whatsapp number
                   </p>
               </div>

               <button className="return-home-button" onClick={returnToHome}>
                   Return to home
               </button>
         </div>
        </div>
    </div>
    );
};

export default RewardsCongratulation;
