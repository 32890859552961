import React from 'react';
import FooterLogo from '@/assets/images/footer_logo.webp'
import AppStoreImg from '@/assets/images/app_stores/app_store.svg'
import GoogleStoreImg from '@/assets/images/app_stores/google_play.svg'

const Footer = () => {
    return (
        <>
            <footer className='text-white' >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <img src={FooterLogo} alt="Logo" />
                            <p className="tagline mt-3 fw-normal">Investment ka fear, hoga ab clear!</p>
                            <div className="d-flex d-md-none align-items-center gap-2 downloadImgContainer ">
                                <div className='w-100'>
                                    <a href="https://play.google.com/store/apps/details?id=com.OpiGo1final"><img src={GoogleStoreImg} className='w-100' alt="Google Store" /></a>
                                </div>
                                <div className='w-100' >
                                    <a href="https://apps.apple.com/in/app/opigo-stock-market-strategies/id1619955231"><img src={AppStoreImg} className='w-100' alt="App Store" /></a>
                                </div>
                            </div>

                            <div className="d-none d-md-flex align-items-center gap-2 downloadImgContainer ">
                                <div className='img-box'>
                                    <a href="https://play.google.com/store/apps/details?id=com.OpiGo1final"><img src={GoogleStoreImg} alt="Google Store" /></a>
                                </div>
                                <div className='img-box' >
                                    <a href="https://apps.apple.com/in/app/opigo-stock-market-strategies/id1619955231"><img src={AppStoreImg} alt="App Store" /></a>
                                </div>
                            </div>

                            <p className="copyright inner-copyright">
                                ©{new Date().getFullYear()} by IJOOLOO Pvt ltd.
                            </p>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center socialIcon">
                                <a target='blank' href="https://www.facebook.com/officialopigo"><span><i className="fa-brands fa-facebook-f"></i></span></a>
                                {/* <a target='blank' href="https://www.youtube.com/channel/UChy1sCZSo_af6wG7HnqV4RA/featured"><span><i className="fa-brands fa-youtube"></i></span></a> */}
                                <a target='blank' href="https://www.linkedin.com/company/officialopigo/"><span><i className="fa-brands fa-linkedin-in"></i></span></a>
                                <a target='blank' href="https://www.instagram.com/officialopigo/"><span><i className="fa-brands fa-instagram"></i></span></a>
                                <a target='blank' href="https://x.com/i/flow/login?redirect_after_login=%2FOfficialOpiGo"><span><i className="fa-brands fa-twitter"></i></span></a>
                            </div>
                            <div className="row d-flex">
                                <div className="col-12 col-md-6">
                                    <h5 className='col-hed'>Contact Us</h5>
                                    <ul className="footer-link">
                                        <li><a href="https://shorturl.at/kzLO6" className='text-white text-decoration-none' ><i className="fa-brands fa-whatsapp"></i> Whatsapp</a></li>
                                        <li><a href="mailto:help@opigo.in" className='text-white text-decoration-none ' ><i className="fa-regular fa-envelope"></i> Email</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h5 className='col-hed'>Support</h5>
                                    <ul className="footer-link">
                                        <li><a href="/privacy_policy" className='text-white text-decoration-none' >Terms and Policy</a></li>
                                        <li><a href="/refund_policy" className='text-white text-decoration-none' >Refund Policy</a></li>
                                        <li><a href="/terms_of_use" className='text-white text-decoration-none' >Terms of Use</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="col-12 col-md-4">
                            <p className="desclaimer">
                                Disclaimer: Investing and trading in the Stock Market come with risks. OpiGo (Owned by IJOOLOO Pvt Ltd) is a social community platform that enables streamlined investment discussions. The information shared on the platform is shared and accessed voluntarily by users. The views are that of the users and neither does OpiGo/IJOOLOO Pvt Ltd endorse any view shared nor is OpiGo/IJOOLOO Pvt Ltd liable for any losses arising out of investment actions taken solely by referring to information on the platform. Consult a SEBI registered investment advisor before taking any decisions
                            </p>
                        </div>
                    </div>

                    <p className="text-center copyright outer-copyright">
                        ©{new Date().getFullYear()} by IJOOLOO Pvt ltd.
                    </p>
                </div>
            </footer>
        </>
    )
};

export default Footer;
