import React from 'react';

const footerStyles = {
    contactHeading: { color: '#525DF9' },
    linkColor: { color: '#000' }
};

const DeckFooter = React.memo(() => {
    return (
        <footer className='text-black px-3 my-5 my-lg-0 footer-container'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <p className="mt-3 fw-regular">Investment ka fear, hoga ab clear!</p>
                        <p className="fs-14 inner-copyright" style={{ marginTop: '12px' }}>
                            ©{new Date().getFullYear()} by IJOOLOO Pvt ltd.
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="row d-flex">
                            <div className="col-12 col-md-6">
                                <h5 className='col-hed mt-4 mt-md-0 text-uppercase' style={footerStyles.contactHeading}>
                                    Contact Us
                                </h5>
                                <ul className="mb-2 list-unstyled d-flex flex-md-column align-items-center align-items-md-start gap-3 mt-3">
                                    <li className='fs-14 fs-md-16'>
                                        <a href="https://shorturl.at/kzLO6" className='text-black text-decoration-none' style={footerStyles.linkColor}>
                                            <i className="fa-brands fa-whatsapp"></i> Whatsapp
                                        </a>
                                    </li>
                                    <li className='fs-14 fs-md-16'>
                                        <a href="mailto:help@opigo.in" className='text-black text-decoration-none' style={footerStyles.linkColor}>
                                            <i className="fa-regular fa-envelope"></i> Email
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                                <h5 className='col-hed mt-4 mt-md-0 text-uppercase' style={footerStyles.contactHeading}>
                                    Support
                                </h5>
                                <ul className="mb-2 list-unstyled d-flex flex-md-column align-items-center align-items-md-start gap-3 mt-3">
                                    <li className='fs-14 fs-md-16'>
                                        <a href="/privacy_policy" className='text-black text-decoration-none' style={footerStyles.linkColor}>
                                            Terms and Policy
                                        </a>
                                    </li>
                                    <li className='fs-14 fs-md-16'>
                                        <a href="/refund_policy" className='text-black text-decoration-none' style={footerStyles.linkColor}>
                                            Refund Policy
                                        </a>
                                    </li>
                                    <li className='fs-14 fs-md-16'>
                                        <a href="/terms_of_use" className='text-black text-decoration-none' style={footerStyles.linkColor}>
                                            Terms of Use
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-0">
                        <p className="fs-14 pb-5 pb-lg-0 fw-light">
                            Disclaimer: Investing and trading in the Stock Market come with risks. OpiGo (Owned by IJOOLOO Pvt Ltd) is a social community platform that enables streamlined investment discussions. The information shared on the platform is shared and accessed voluntarily by users. The views are that of the users and neither does OpiGo/IJOOLOO Pvt Ltd endorse any view shared nor is OpiGo/IJOOLOO Pvt Ltd liable for any losses arising out of investment actions taken solely by referring to information on the platform. Consult a SEBI registered investment advisor before taking any decisions.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default DeckFooter;
