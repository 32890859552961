import { axios } from '@/lib/axios';
import {
    cardValueDiscount, checkBoxResponse, getFeatureFlag,
    getMyGiftList,
    getProductList,
    giftPurchaseAPIResponse,
    giftPurchaseBody,
} from '@/features/giftcard/types';

//Brand list main screen
export const getRewards = (): Promise<getProductList> => {
    return axios.get(`/wohoo_products`);
};


export const giftPurchaseFlag = (): Promise<getFeatureFlag> => {
    return axios.get(`/gift_purchase/feature_flag`);
};

//Wallet balance
export const getWalletBalance = (): Promise<getProductList> => {
    return axios.get(`/accounts/wallet`);
};

//My Gift Cards list API
export const getGiftPurchaseList = (): Promise<getMyGiftList> => {
    return axios.get(`/gift_purchase/gift_purchases_list`);
};

//My Gift Cards list API
export const getCardValueDiscount = (id: string, amount: any): Promise<cardValueDiscount> => {
    return axios.get(`gift_purchase/validate_purchase_amount?product_id=${id}&amount=${amount}`);
};

//Check box click
export const checkBoxApplyDiscount = (data:any, token: string, id: string): Promise<checkBoxResponse> => {
    return axios.post(`gift_purchase/calculate_discount?product_id=${id}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
};

//Post gift_purchase/purchase
export const giftPurchase = (data: giftPurchaseBody, token: string): Promise<giftPurchaseAPIResponse> => {
    return axios.post(`gift_purchase/purchase?source=web&devise_os=web`, data, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
};
