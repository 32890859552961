interface CategorizedDurations {
    minutes: DurationItem[];
    hours: DurationItem[];
    day: DurationItem[];
    days: DurationItem[];
    month: DurationItem[];
    months: DurationItem[];
    years: DurationItem[];
    lifetime: DurationItem[];
}

interface DurationItem {
    value: number;
    text: string;
}

export const categorizeAndSortTimeDurations = (timeDurations: string[]): string[] => {
    const categorized: CategorizedDurations = {
        minutes: [],
        hours: [],
        day: [],
        days: [],
        month: [],
        months: [],
        years: [],
        lifetime: [],
    };

    try {
        timeDurations.forEach(duration => {
            const [value, unit] = duration.split(' ');
            const intValue = parseInt(value);

            if (!unit) {
                categorized.lifetime.push({ value: 0, text: duration });
            }

            switch (unit) {
                case 'Minutes':
                    categorized.minutes.push({ value: intValue, text: duration });
                    break;
                case 'Hours':
                    categorized.hours.push({ value: intValue, text: duration });
                    break;
                case 'Day':
                    categorized.day.push({ value: intValue, text: duration });
                    break;
                case 'Days':
                    categorized.days.push({ value: intValue, text: duration });
                    break;
                case 'Month':
                    categorized.month.push({ value: intValue, text: duration });
                    break;
                case 'Months':
                    categorized.months.push({ value: intValue, text: duration });
                    break;
                case 'Years':
                    categorized.years.push({ value: intValue, text: duration });
                    break;
                default:
                    break;
            }
        });

        for (const key in categorized) {
            if (key !== 'lifetime') {
                categorized[key as keyof CategorizedDurations].sort((a, b) => a.value - b.value);
            }
        }

        const sortedDurations = [
            ...categorized.minutes.map(item => item.text),
            ...categorized.hours.map(item => item.text),
            ...categorized.day.map(item => item.text),
            ...categorized.days.map(item => item.text),
            ...categorized.month.map(item => item.text),
            ...categorized.months.map(item => item.text),
            ...categorized.years.map(item => item.text),
            ...categorized.lifetime.map(item => item.text),
        ];

        return sortedDurations;
    } catch (error) {
        return timeDurations;
    }
};


export const getUniqueArray = (array: any[]) => {
    const tempSet = new Set(array);
    const uniqueFilters: any[] = [];

    tempSet.forEach((value) => {
        uniqueFilters.push(value);
    });

    return uniqueFilters;
}

export const getUtmMedium = () => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('utm_source');
    const medium = params.get('utm_medium');
    const campaign = params.get('utm_campaign');
    const term = params.get('utm_term');
    const content = params.get('utm_content');

    if (!source && !medium && !campaign && !term && !content) {
        return ''
    }

    const validParams = [];

    if (source) {
        validParams.push(`utm_source=${source}`);
    }
    if (medium) {
        validParams.push(`utm_medium=${medium}`);
    }
    if (campaign) {
        validParams.push(`utm_campaign=${campaign}`);
    }
    if (term) {
        validParams.push(`utm_term=${term}`)
    }
    if (content) {
        validParams.push(`utm_content=${content}`)
    }

    const queryString = validParams.join('&');

    return queryString;
};

export const getQueryParam = (value: string) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(value)
}

export const manageFilters = (data: any) => {
    let temp: string[] = [];

    if(data?.length > 0){
        data?.map((_: any) => {
            _.channel_packages?.map((item: any) => {
                if (item.plan_type) {
                    temp.push(item.plan_type)
                }
            })
        });

        const filters = categorizeAndSortTimeDurations(getUniqueArray(temp));
        const selectedTime = filters[0];
        return { filters, selectedTime }
    }
    else {
        return { filters: [], selectedTime: '' };
    }


}

export const filterDataBasedOnTime = (data: any, selectedTime: any) => {
    let filterdData: any = [];
    let lowestPackage: any = Infinity;

    if(data?.length > 0){
        data?.map((deck: any) => {
            deck?.channel_packages?.map((item: any) => {
                if (item.plan_type === selectedTime) {

                    if (item.price < lowestPackage) {
                        lowestPackage = item.price;
                    }
                    filterdData.push(deck);
                }
            })
        });
        return { filterdData, lowestPackage }
    }
    else {
        return { filteredData: [], lowestPackage: '' };
    }
}

export const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthAbbr[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};
