import { Header } from '@/components/Elements';
import * as React from 'react';



export function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container py-3">
        <div className="row">
          <div className="col-12">
            <h2>Privacy Policy</h2>
            <p >
              <span >We are committed to protecting your privacy. This policy outlines how OpiGo, a product owned by Ijooloo Private Limited, hereinafter collectively referred to as “Opigo”, collects, uses, stores and discloses your personal information. By browsing this Site, sharing your personal information on</span><span >&#xa0;</span><a href="http://www.opigo.in/" target="_blank" ><u><span >www.opigo.in</span></u></a><span >&#xa0;</span><span >(“Site”) or the mobile app (“Application”), you agree to the terms of this policy. For the purpose of this policy, Sit</span>
            </p>
            <p >
              <span >If you have any concerns or questions regarding this Privacy Policy, please reach out to us at</span><span >&#xa0;</span><a href="mailto:help@opigo.in" target="_self" ><u><span >help@opigo.in</span></u></a><span >.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >What information do we collect?</span></strong>
            </p>
            <p >
              <span >By browsing the Site and using the services provided by OpiGo, you may provide information falling under these categories:</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >a. </span><span >Personally Identifiable Information: Personal data including but not limited to name, mobile number, birth date, email address, qualifications, supporting documents, etc.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >b. </span><span >Permissions: Permission to access location, camera, gallery access, storage permissions, etc. for Application functions such sharing media, profile picture upload, etc.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >c. </span><span >Application Analytics: Application usage analytics in line with the services provided by OpiGo under its Terms &amp; Conditions. For example: Feature you use the most/least, number of followers, etc.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >d. </span><span > Cookies and Browsing Data: Cookies are small pieces of data stored by a user’s Web browser on the user’s hard drive. We use standard cookies to collect information about how the Site or Application are used. Cookies generally collect information such as time of visit, pages viewed, time spent, etc. This information is collected on an aggregate basis and is not personally identifiable. By continuing to browse the site, you are agreeing to our use of cookies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are used. If you disable or refuse cookies, please note that some parts of this Site/Application may become inaccessible or not function properly.</span>
            </p>
            <p >
              <span >​</span>
            </p>
            <p >
              <strong><span >How do we use your personal information?</span></strong>
            </p>
            <p >
              <span >The information provided by you shall be used to provide and improve the service for you and all users.</span>
            </p>
            <p >
              <span >a. </span><span > </span><span > </span><span >To provide you with services</span>
            </p>
            <p >
              <span >b. </span><span > </span><span > </span><span >For maintaining an internal record</span>
            </p>
            <p >
              <span >c. </span><span > </span><span > </span><span >For enhancing the Services provided</span>
            </p>
            <p >
              <span >d. </span><span > </span><span > </span><span >For maintaining record under the legal and statutory provisions</span>
            </p>
            <p >
              <span >e. </span><span > </span><span > </span><span >Communicating with you about Services or changes in policies</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >We will not share, disclose, sell, trade your personal information in ways unrelated to the services offered by OpiGo without providing you with an opportunity to either opt out of such use or prohibit any unrelated use. We may provide access to your personal information only when required to do so by law. We will collect information by lawful and ethical means with your prior knowledge and consent.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Third Party Services</span></strong>
            </p>
            <p >
              <span >We may use third party services, such as Google Analytics, to help us understand how you access and use our Site/Application and to improve our services for you. This information collected by third parties is anonymous and in the form of aggregated reports or trends about pages visited, time spent, etc. You can control this information through disabling Cookies.</span>
            </p>
            <p >
              <span >External Links</span>
            </p>
            <p >
              <span >The Site/Application contains links to third party sites which may include advertisements, news articles, blogs, user websites, etc. We are not responsible for the privacy policies of any third parties and are not liable for them.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Retention</span></strong>
            </p>
            <p >
              <span >We will only retain information as long as necessary for the fulfilment of OpiGo’s purposes and as required by law.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Modification</span></strong>
            </p>
            <p >
              <span >If you wish to modify any data shared with us, please use the options available on the Site or the Application to make the changes. Alternately, you can reach to us at</span><span >&#xa0;</span><a href="mailto:help@opigo.in" target="_self" ><u><span >help@opigo.in</span></u></a><span >&#xa0;</span><span >for specific requests.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Security</span></strong>
            </p>
            <p >
              <span >We employ reasonable security measures as per industry standards to protect your data from unauthorized and unlawful access, disclosure, or loss. However, due to the nature of transmission of data on the Internet, OpiGo cannot ensure the security of data transmitted over the Internet.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Severability</span></strong>
            </p>
            <p >
              <span >Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >Amendment</span></strong>
            </p>
            <p >
              <span >Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Application. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Application.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <strong><span >GDPR Privacy Policy (Data Protection Rights)</span></strong>
            </p>
            <p >
              <span >We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</span>
            </p>
            <p >
              <span >The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</span>
            </p>
            <p >
              <span >The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</span>
            </p>
            <p >
              <span >The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</span>
            </p>
            <p >
              <span >The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</span>
            </p>
            <p >
              <span >The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</span>
            </p>
            <p >
              <span >The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</span>
            </p>
            <p >
              <span >If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us on</span><span >&#xa0;</span><a href="mailto:help@opigo.in" ><u><span >help@opigo.in</span></u></a>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >To withdraw your consent, or to request the download or deletion of your personal data with us for any or all our services at any time, please reach out to us at</span><span >&#xa0;</span><a href="mailto:help@opigo.in" target="_self" ><u><span >help@opigo.in</span></u></a><span >. You should be aware, however, it may not always be possible to completely remove or modify information such as your activity on the application from our databases.</span>
            </p>
            <p >
              <span >&#xa0;</span>
            </p>
            <p >
              <span >This Privacy Policy was updated on November 28, 2022.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

