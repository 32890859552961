import * as React from 'react';

export interface IBadgeProps {
  active: boolean
  completed: boolean
  content: string | React.ReactNode
}

export function Badge({ active, completed, content }: IBadgeProps) {

  const resolveBadgeClass = () => {
    if (completed)
      return "text-bg-success"
    else if (active)
      return "text-bg-dark"
    else
      return "text-bg-outline-dark"
  }

  return (
    <div className={`${resolveBadgeClass()} rounded-circle d-flex align-items-center justify-content-center text-white me-3 fs-20 fw-medium`} style={{width: 30, height: 30}} >{content}</div>
  );
}

